// Media query 
@mixin respond-to($breakpoint) {
  @if $breakpoint=='sm' {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  }

  @else if $breakpoint=='md' {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  }

  @else if $breakpoint=='lg' {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  }

  @else if $breakpoint=='xl' {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  }
}


@mixin flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}