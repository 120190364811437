@import './variables';
@import './mixins';
@import './dropdown';

.dash-table {
  width: 100%;
  overflow-x: auto;
  min-height: 40rem;

  .table {
    margin-bottom: 0rem;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;

    &>thead {

      &>tr {

        &>* {
          vertical-align: middle;
          padding: 1rem;
          border: 0.1rem solid $border-1;
        }

        &>th {
          color: $secondary-color-2;
          font-family: $font-family-base;
          font-size: $fs-14;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          line-height: $lh-normal;

          .btn {
            img {
              max-width: $spacing-unit;
            }

            span {
              @include flex-center;
            }
          }
        }
      }
    }

    .check-table {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: #{$spacing-unit - 0.2rem};

      .form-check-input {
        width: 1.35rem;
        height: 1.35rem;
        border: 0.15rem solid $secondary-color-2;
        border-radius: 0.2rem !important;
        -webkit-border-radius: 0.2rem !important;
        -moz-border-radius: 0.2rem !important;
        -ms-border-radius: 0.2rem !important;
        -o-border-radius: 0.2rem !important;

        &:checked {
          background-color: $primary-color;
          border-color: $primary-color;
          background-image: url("../icons/check-right.svg");
          background-origin: padding-box;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    &>thead,
    &>tbody {

      &>tr {

        &>th,
        &>td {

          &:first-child {
            border-radius: 0.4rem 0rem 0rem 0.4rem;
            -webkit-border-radius: 0.4rem 0rem 0rem 0.4rem;
            -moz-border-radius: 0.4rem 0rem 0rem 0.4rem;
            -ms-border-radius: 0.4rem 0rem 0rem 0.4rem;
            -o-border-radius: 0.4rem 0rem 0rem 0.4rem;
          }

          &:last-child {
            border-radius: 0rem 0.4rem 0.4rem 0rem;
            -webkit-border-radius: 0rem 0.4rem 0.4rem 0rem;
            -moz-border-radius: 0rem 0.4rem 0.4rem 0rem;
            -ms-border-radius: 0rem 0.4rem 0.4rem 0rem;
            -o-border-radius: 0rem 0.4rem 0.4rem 0rem;
          }
        }
      }
    }

    &>tbody {

      &>tr {

        &>* {
          vertical-align: middle;
          padding: 1rem;
          border: 0.1rem solid $border-1;
        }

        .btn-icon {
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          &>* {
            // background: #EDEDEF;
            // background: rgba(52, 59, 78, 0.06);
            background-color: rgb(245 245 245);
          }

          .btn-icon {
            visibility: visible;
            opacity: 1;
          }
        }

        &>td {
          white-space: nowrap;
          color: $secondary-color-2;
          font-family: $font-family-base;
          font-size: $fs-14;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          line-height: $lh-normal;

          &:last-child {
            border-radius: 0.4rem;
            -webkit-border-radius: 0.4rem;
            -moz-border-radius: 0.4rem;
            -ms-border-radius: 0.4rem;
            -o-border-radius: 0.4rem;
          }

          .title {
            color: $text-color;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-500;
            line-height: $lh-normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: #{$spacing-unit - 0.3rem};
            text-transform: capitalize;

            a {
              color: $text-color;
              text-decoration: none;
              max-width: 25rem;
            }

            &.text-main {
              color: $primary-color;
            }

            &.text-second {
              color: $text-light-grey;
            }
          }

          .small-text-muted {
            color: $secondary-color-2;
            font-family: $font-family-base;
            font-size: $fs-12;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
          }

          .btn-add-task {
            color: $secondary-color-2;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
            @include flex-center;
            gap: #{$spacing-unit + 0.2rem};
          }

          p {
            color: $text-dark;
            font-family: $font-family-base-3;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: 2.2rem;
            margin-bottom: 0rem;
            display: flex;
            align-items: center;
            justify-content: start;
            column-gap: 1.7rem;
          }

          .text-muted {
            color: #aca9a9;
            font-family: $font-family-base-3;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
          }

          &:nth-child(4) {
            p {
              justify-content: center;
            }
          }
        }
      }
    }

    thead,
    tbody {
      tr {

        th,
        td {
          z-index: 1;
        }

        &>:nth-child(1),
        &>:nth-child(2),
        &>:nth-child(3) {
          position: sticky;
          left: 0;
          z-index: 2;

          .dropdown-menu{
            z-index: 20;
            position: absolute;
          }
        }

        &>:nth-child(1) {
          width: 5.01rem;
        }

        &>:nth-child(2) {
          left: 5.01rem;
          width: 38rem;
          min-width: 38rem;
          max-width: 38rem;
        }

        &>:nth-child(3) {
          left: 43.01rem;
        }
      }
    }
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f6f6f6;
  //   border-radius: 1rem;
  //   -webkit-border-radius: 1rem;
  //   -moz-border-radius: 1rem;
  //   -ms-border-radius: 1rem;
  //   -o-border-radius: 1rem;
  // }

  // &::-webkit-scrollbar {
  //   width: 0.5rem;
  //   height: 0.2rem;
  //   background-color: #f6f6f6;
  // }

  // &::-webkit-scrollbar-thumb {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #000000;
  //   border-radius: 1rem;
  //   -webkit-border-radius: 1rem;
  //   -moz-border-radius: 1rem;
  //   -ms-border-radius: 1rem;
  //   -o-border-radius: 1rem;
  // }
}