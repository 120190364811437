@import './variables';
@import './mixins';
@import './dropdown';

*,
::after,
::before {
  padding: 0rem;
  margin: 0rem;
  box-sizing: border-box;
}

*,
:focus,
:focus-visible,
:focus-within {
  outline: 0;
  border: 0rem;
  box-shadow: none;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $text-color;
  font-weight: $font-weight-normal;
  line-height: normal;
}

*::-webkit-scrollbar {
  background-color: #f6f6f6;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

.max-content-width {
  max-width: 1440px;
  margin: 0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
textarea,
select:focus,
input,
textarea,
select:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

button,
a:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}

button,
a:focus-visible {
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}

.tooltip {
  color: var(--heading-colour, #000000);
  font-family: "Inter";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  display: inline-block;
  position: relative;
  display: inline-block;
}

.tooltip_dir {
  position: absolute;
  background-color: black;
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1000;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $spacing-unit * 2;
  font-weight: $font-weight-normal;
}

a {
  color: $primary-color;
  text-decoration: none;
}

.btn {
  &.btn-primary {
    color: $text-white;
    text-align: center;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: $lh-normal;
    padding: 1.2rem 4.5rem;
    background: $primary-color;
    border-radius: #{$radius-6 * 2};
    -webkit-border-radius: #{$radius-6 * 2};
    -moz-border-radius: #{$radius-6 * 2};
    -ms-border-radius: #{$radius-6 * 2};
    -o-border-radius: #{$radius-6 * 2};

    &:hover,
    &:active,
    &:focus {
      background: $primary-color-2;
    }

    &:disabled,
    &.disabled {
      background: rgba($primary-color, 0.65);
      border-color: rgba($primary-color, 0.65);
    }
  }

  &.btn-secondary {
    color: $secondary-color-2;
    text-align: center;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: $lh-normal;
    padding: 1.2rem 4.5rem;
    background: $secondary-color;
    border-radius: #{$radius-6 * 2};
    -webkit-border-radius: #{$radius-6 * 2};
    -moz-border-radius: #{$radius-6 * 2};
    -ms-border-radius: #{$radius-6 * 2};
    -o-border-radius: #{$radius-6 * 2};
    border-color: $secondary-color;

    &:hover,
    &:active,
    &:focus {
      background: #c3c6ca;
      border-color: #c3c6ca;
      color: $secondary-color-2;
    }
  }

  &.btn-danger-gradient {
    border-radius: #{$radius-6 * 2} !important;
    -webkit-border-radius: #{$radius-6 * 2} !important;
    -moz-border-radius: #{$radius-6 * 2} !important;
    -ms-border-radius: #{$radius-6 * 2} !important;
    -o-border-radius: #{$radius-6 * 2} !important;
    background: linear-gradient(96deg, #FF4747 28.03%, #FC23B0 83.44%) !important;
    padding: 0.6rem 1.4rem !important;
    column-gap: 0.7rem !important;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: $lh-normal;
    color: $text-white;
    width: 10rem;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      color: $text-white;
    }
  }
}

.dashboard-tabs {
  margin-bottom: #{$spacing-unit * 5 - 0.2rem};
  border-bottom: 0.2rem solid $border-light-grey-2;
  @include flex-center;
  justify-content: space-between;

  .nav {
    .nav-item {
      .nav-link {
        padding: 1rem 3.5rem;
        color: $text-dark;
        font-family: $font-family-base;
        font-size: $fs-14;
        font-style: $font-style-normal;
        font-weight: $font-weight-500;
        line-height: $lh-normal;
        border-radius: 0rem;
        -webkit-border-radius: 0rem;
        -moz-border-radius: 0rem;
        -ms-border-radius: 0rem;
        -o-border-radius: 0rem;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          background-color: $primary-color;
          height: 0.2rem;
          top: 100%;
          z-index: 1;
          transform-origin: 100% 50%;
          transform: scaleX(0);
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        }

        &.active,
        &:active {
          background: transparent;
          color: $primary-color;

          &::before {
            transform-origin: 0% 50%;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

.welcome-user {
  margin-bottom: 3.9rem;

  .user-name {
    color: $text-dark;
    font-family: $font-family-base;
    font-size: $fs-10;
    font-style: $font-style-normal;
    font-weight: $font-weight-700;
    line-height: $lh-normal;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.7rem 0.8rem;
    @include flex-center;
    gap: #{$spacing-unit + 0.2rem};
    background: $primary-2;
    border-radius: #{$radius-8 * 3 + 0.1rem};
    -webkit-border-radius: #{$radius-8 * 3 + 0.1rem};
    -moz-border-radius: #{$radius-8 * 3 + 0.1rem};
    -ms-border-radius: #{$radius-8 * 3 + 0.1rem};
    -o-border-radius: #{$radius-8 * 3 + 0.1rem};
  }

  h4 {
    color: $text-dark;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-600;
    line-height: $lh-normal;
  }

  .flex-gap {
    gap: #{$spacing-unit * 3 + 0.1rem};
  }

  .btn {
    display: flex;
    align-items: center;
    padding: $spacing-unit;
    gap: #{$spacing-unit + 0.2rem};
    background: $bg-3;
    border-radius: $radius-6;
    -webkit-border-radius: $radius-6;
    -moz-border-radius: $radius-6;
    -ms-border-radius: $radius-6;
    -o-border-radius: $radius-6;
    border: 0rem;

    &:hover,
    &:active {
      background: $bg-3;
    }

    &.btn-light-primary {
      border: 0.1rem solid $border-light-grey;
      background-color: $text-white;
      font-family: $font-family-base;
      font-size: $fs-12;
      color: $text-dark;
      padding: $spacing-unit $padding-base;

      .icon {
        width: 1.86rem;
        height: 1.86rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9747FF17;
        padding: 0.3rem;
        border-radius: 0.4rem;
        -webkit-border-radius: 0.4rem;
        -moz-border-radius: 0.4rem;
        -ms-border-radius: 0.4rem;
        -o-border-radius: 0.4rem;
      }

      &:hover,
      &:active,
      &.active {
        background-color: rgba(111, 0, 255, 0.03);
        border-color: $primary-color;
      }
    }
  }

  p {
    color: $Neutral-Solid-500;
    font-family: $font-family-base;
    font-size: #{$fs-12 + 0.1rem};
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: $lh-normal;
  }
}

.filter-sort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: #{$spacing-unit - 0.1rem};

  .btn {
    &::after {
      content: none;
    }

    &.btn-fill {
      background: $bg-3;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.4rem 0.8rem;
      color: $text-dark;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-normal;
      line-height: $lh-normal;
      border-radius: $radius-6;
      -webkit-border-radius: $radius-6;
      -moz-border-radius: $radius-6;
      -ms-border-radius: $radius-6;
      -o-border-radius: $radius-6;
    }
  }

  .dropdown-menu {
    background: $text-white;
    box-shadow: $shadow-2;
    padding: 1.2rem 1.1rem;
    border: 0rem;
    min-width: 17.2rem;

    .drop-head {
      .title {
        color: $Neutral-Solid-600;
        font-family: $font-family-base;
        font-size: $fs-12;
        font-style: $font-style-normal;
        font-weight: $font-weight-normal;
        line-height: $lh-normal;
      }

      .btn-cancel {
        color: $text-red-3;
        font-family: $font-family-base;
        font-size: #{$fs-10 - 0.2rem};
        font-style: $font-style-normal;
        font-weight: $font-weight-normal;
        line-height: $lh-normal;
      }
    }

    ul {
      li {
        padding: 0.2rem 0rem;

        a {
          color: $text-color;
          font-family: $font-family-base;
          font-size: $fs-12;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          line-height: $lh-normal;
          padding: 0rem;
        }

        .btn-issue {
          border: 0.1rem solid $bg-8;
          background: $text-white;
          border-radius: $radius-18;
          -webkit-border-radius: $radius-18;
          -moz-border-radius: $radius-18;
          -ms-border-radius: $radius-18;
          -o-border-radius: $radius-18;
          font-size: 0.8rem;
          padding: 0.2rem 0.7rem;
        }

        .btn-filter-by {
          border: 0.1rem solid $bg-8;
          background: $text-white;
          border-radius: $radius-18;
          -webkit-border-radius: $radius-18;
          -moz-border-radius: $radius-18;
          -ms-border-radius: $radius-18;
          -o-border-radius: $radius-18;
          padding: 0.3rem 0.7rem;
          @include flex-center;
          gap: #{$spacing-unit - 0.4rem};

          &.active {
            border-color: $primary-color;
          }

          label {
            @include flex-center;
            padding: 0rem 0.4rem;
            border-radius: 1.3rem;
            -webkit-border-radius: 1.3rem;
            -moz-border-radius: 1.3rem;
            -ms-border-radius: 1.3rem;
            -o-border-radius: 1.3rem;
            font-family: $font-family-base;
            font-size: $fs-10;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
            cursor: pointer;

            &.bg-gray-25 {
              color: $text-color;
              background: $bg-grey-25 !important;
            }

            &.bg-white-25 {
              border: 0.1rem solid $bg-2;
              background: $text-white;
              color: $text-color;
            }

            &.bg-orange-25 {
              background: $bg-orange-25;
              color: $text-orange-300;
            }

            &.bg-blue-25 {
              background: $bg-blue-25;
              color: $text-blue-300;
            }

            &.bg-red-25 {
              background: $bg-red-25;
              color: $text-red-300;
            }

            &.bg-yellow-25 {
              background: $bg-yellow-25;
              color: $text-yellow-300;
            }

            &.bg-purple-25 {
              background: $bg-purple-25;
              color: $primary-color;
            }

            &.bg-pink-25 {
              background: $bg-pink-25;
              color: $text-pink-300;
            }

            &.bg-green-25 {
              background: $bg-green-25;
              color: $text-green-300;
            }
          }

          input {
            border: 0.02rem solid $text-light-grey;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 0.1rem;
            margin: 0rem;

            &:checked {
              background-color: $primary-color;
              border-color: $primary-color;
              background-image: url("../icons/check-right.svg");
              background-origin: padding-box;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }

        .filter-by-day {
          position: relative;

          .form-control {
            padding-right: 2rem;
            max-width: 7.3rem;
          }

          .form-label {
            position: absolute;
            top: 44%;
            right: 0.6rem;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
          }
        }
      }
    }
  }
}

// Add Collabrator start here...

.collabrator-content {
  background: $text-white;
  box-shadow: $shadow-2;
  min-width: 46.1rem;
  padding: 2.6rem 2.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: #{$spacing-unit * 3 + 0.1rem};
  position: absolute;
  bottom: 0%;
  left: 0%;
  transition: opacity 200ms ease-in, transform 200ms ease-in,
    visibility 200ms ease-in;
  visibility: hidden;
  opacity: 0;
  z-index: 111;
  transform: translate(4%, 0);
  -webkit-transform: translate(4%, 0);
  -moz-transform: translate(4%, 0);
  -ms-transform: translate(4%, 0);
  -o-transform: translate(4%, 0);

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translate(4%, -6rem);
    -webkit-transform: translate(4%, -6rem);
    -moz-transform: translate(4%, -6rem);
    -ms-transform: translate(4%, -6rem);
    -o-transform: translate(4%, -6rem);
  }

  &.share {
    top: 100%;
    bottom: auto;
    right: 0%;
    left: auto;
    transform: translate(0, 3rem);
    -webkit-transform: translate(0, 3rem);
    -moz-transform: translate(0, 3rem);
    -ms-transform: translate(0, 3rem);
    -o-transform: translate(0, 3rem);

    &.show {
      transform: translate(0, 0rem);
      -webkit-transform: translate(0, 0rem);
      -moz-transform: translate(0, 0rem);
      -ms-transform: translate(0, 0rem);
      -o-transform: translate(0, 0rem);
    }
  }

  &.help {
    min-width: 74.5rem;
  }

  .card-head {
    .title {
      color: $text-dark;
      font-family: $font-family-base;
      font-size: #{$fs-12 * 2 + 0.1rem};
      font-style: $font-style-normal;
      font-weight: $font-weight-500;
      line-height: $lh-normal;
      letter-spacing: -0.046rem;
    }

    .desc {
      color: $secondary-color-2;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-normal;
      line-height: $lh-normal;
      max-width: 34.7rem;
    }
  }

  .form-group {
    .form-label {
      color: $text-dark-2;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-500;
      line-height: 157.143%;
      margin-bottom: 0.5rem;
    }

    .form-control {
      border: 0.1rem solid $bg-2;
      background: $text-white;
      padding: 1.5rem;
      border-radius: $radius-6;
      -webkit-border-radius: $radius-6;
      -moz-border-radius: $radius-6;
      -ms-border-radius: $radius-6;
      -o-border-radius: $radius-6;
      color: $text-dark;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-normal;
      line-height: 157.143%;

      &::placeholder {
        color: $text-light-grey;
      }

      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within {
        border-color: $primary-color;
      }
    }

    .input-with-icon {
      position: relative;

      .form-control {
        padding: 1.5rem 4.1rem;
      }

      span,
      .btn {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;
      }

      .btn {
        right: 1.5rem;
        left: auto;

        span {
          right: 0rem;
          left: auto;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.9rem;
    padding: 1rem 0rem;
    margin-bottom: 0rem;

    .btn {
      text-align: center;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-normal;
      line-height: $lh-normal;
      padding: 1rem 1.6rem;
      border-radius: 1rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;

      &.btn-secondary {
        color: $secondary-color-2 !important;
        background: $secondary-color !important;
        border: 0rem;
      }

      &.btn-primary {
        color: $text-white !important;
        background: $primary-color !important;
        border: 0rem;
      }
    }
  }
}

// Add Collabrator end here...

.offcanvas {
  &.task-detail {
    height: calc(100dvh - 7.3rem);
    top: auto !important;
    bottom: 0 !important;
    position: fixed !important;
    min-width: 64.1rem;

    app-workflow-task-assign {
      height: 100%;
    }
  }
}

.task-detail {
  .offcanvas-header {
    border: 0.1rem solid #e0e0e0;
    background: rgba(52, 59, 78, 0.06);
    padding: 2.8rem 3.4rem 1.4rem 3.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .offcanvas-title {
      color: #343b4e;
      font-family: "Poppins";
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .offcanvas-body {
    padding: 2rem 2.5rem;
    height: 100%;
  }
}

.task-titles,
.task-desc {
  margin-bottom: 2.5rem;
}

.task-titles {
  .dropdown {
    .btn-pro {
      color: var(--text-white);
      font-family: "Poppins";
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: #6F00FF;
      padding: 0.1rem 1rem;
      border-radius: 0.4rem;
      -webkit-border-radius: 0.4rem;
      -moz-border-radius: 0.4rem;
      -ms-border-radius: 0.4rem;
      -o-border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;
      margin-bottom: 0.8rem;

      &::after {
        content: none;
      }
    }
  }

  .title {
    color: #343B4E;
    font-family: "Poppins";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.flex-gap {
  gap: 2.5rem !important;
}

.task-desc {
  .form-label {
    color: #282828;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Poppins";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 157.143%;
    margin-bottom: 0.5rem;
    -webkit-font-feature-settings: 'liga' off, 'clig' off;
    -moz-font-feature-settings: 'liga' off, 'clig' off;
  }

  .form-control {
    border: 0.1rem solid #E8E9EA;
    font-feature-settings: 'liga' off, 'clig' off;
    background: #FFFFFF;
    color: #282828;
    font-family: "Poppins";
    font-size: 1.4rem;
    font-style: normal;
    padding: 1.5rem;
    border-radius: 0.6rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    -ms-border-radius: 0.6rem;
    -o-border-radius: 0.6rem;
    -webkit-font-feature-settings: 'liga' off, 'clig' off;
    -moz-font-feature-settings: 'liga' off, 'clig' off;

    &::placeholder {
      color: #949CA9;
    }
  }
}

.form-control:focus,
.form-control:focus-visible,
.form-control:focus-within {
  border-color: #6F00FF !important;
}

.task-content {
  margin-bottom: 3.6rem;

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      &:not(:last-child) {
        margin-bottom: 1.1rem;
      }

      p {
        color: #666D80;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Poppins";
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0rem;
        min-width: 9.4rem;
      }

      .task-config {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        border: 0.1rem solid #E0E0E0;
        background: rgba(52, 59, 78, 0.06);

        .collab {
          color: #666D80;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;

          span {
            width: 1.7rem;
            height: 1.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: #6F00FF;
            color: var(--text-white);
            border-radius: 1.7rem;
            -webkit-border-radius: 1.7rem;
            -moz-border-radius: 1.7rem;
            -ms-border-radius: 1.7rem;
            -o-border-radius: 1.7rem;
          }
        }

        .dropdown {
          .btn {
            &::after {
              content: none;
            }
          }

          .dropdown-menu {
            background: #FFFFFF;
            box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.10);
            min-width: 17.2rem;
            padding: 1.2rem 1.1rem;
            background: #ffffff;
            box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
            border: 0rem;
            padding: 1.2rem 1.1rem;
            min-width: 17.2rem;

            .drop-head {
              h5 {
                color: #8f8a8a;
                font-family: "Poppins";
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            ul {
              li {
                margin-bottom: 0rem !important;
              }
            }

            .drop-footer {
              border-top: 0.1rem solid #e1e1e1;
              margin-top: 0.5rem;
              padding-top: 1.1rem;
            }
          }
        }

        span {
          &.badge {
            font-family: $font-family-base;
            font-size: $fs-10;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
            @include flex-center;
            padding: 0.2rem 2.3rem;
            gap: #{$spacing-unit + 0.2rem};
            border-radius: 0.4rem;
            -webkit-border-radius: 0.4rem;
            -moz-border-radius: 0.4rem;
            -ms-border-radius: 0.4rem;
            -o-border-radius: 0.4rem;

            &.bg-gray-25 {
              color: $text-color;
              background: $bg-grey-25 !important;
            }

            &.bg-white-25 {
              border: 0.1rem solid $bg-2;
              background: $text-white;
              color: $text-color;
            }

            &.bg-orange-25 {
              background: $bg-orange-25;
              color: $text-orange-300;
            }

            &.bg-blue-25 {
              background: $bg-blue-25;
              color: $text-blue-300;
            }

            &.bg-red-25 {
              background: $bg-red-25;
              color: $text-red-300;
            }

            &.bg-yellow-25 {
              background: $bg-yellow-25;
              color: $text-yellow-300;
            }

            &.bg-purple-25 {
              background: $bg-purple-25;
              color: $primary-color;
            }

            &.bg-pink-25 {
              background: $bg-pink-25;
              color: $text-pink-300;
            }

            &.bg-green-25 {
              background: $bg-green-25;
              color: $text-green-300;
            }

          }
        }

        .time {
          color: #343B4E;
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

.task-content {
  margin-bottom: 3.6rem;

  ul {
    .config-li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1rem;

      &:not(:last-child) {
        margin-bottom: 1.1rem;
      }

      p {
        color: $secondary-color-2;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: $font-family-base;
        font-size: $fs-14;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0rem;
      }

      .task-config {
        min-width: 100%;
        // height: 4rem;
        padding: 0.95rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.9rem;
        border: 0.1rem solid $bg-2;
        background: $text-white;
        border-radius: 0.6rem;
        -webkit-border-radius: 0.6rem;
        -moz-border-radius: 0.6rem;
        -ms-border-radius: 0.6rem;
        -o-border-radius: 0.6rem;

        .collab {
          color: $secondary-color-2;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: $font-family-base;
          font-size: $fs-14;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: $lh-normal;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;

          span {
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: $font-family-base;
            font-size: $fs-12;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: normal;
            background: $primary-color;
            color: var(--text-white);
            border-radius: 1.7rem;
            -webkit-border-radius: 1.7rem;
            -moz-border-radius: 1.7rem;
            -ms-border-radius: 1.7rem;
            -o-border-radius: 1.7rem;
          }
        }

        .dropdown {
          .btn {
            &::after {
              content: none;
            }
          }

          .dropdown-menu {
            background: #FFFFFF;
            box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.10);
            min-width: 17.2rem;
            padding: 1.2rem 1.1rem;
            background: #ffffff;
            box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
            border: 0rem;
            padding: 1.2rem 1.1rem;
            min-width: 17.2rem;

            .drop-head {
              h5 {
                color: #8f8a8a;
                font-family: "Poppins";
                font-size: $fs-12;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            ul {
              li {
                margin-bottom: 0rem !important;

                a {
                  font-size: 1.4rem;
                }
              }
            }

            .drop-footer {
              border-top: 0.1rem solid #e1e1e1;
              margin-top: 0.5rem;
              padding-top: 1.1rem;
            }
          }
        }
      }
    }
  }
}

.btns {
  margin-bottom: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.9rem;

  .btn {
    padding: 1.2rem 4rem;
  }
}

.text-purple {
  color: #6F00FF !important;
}

.task-activity {
  padding-bottom: 3.8rem;
  border-bottom: 0.1rem solid rgba(148, 156, 169, 0.44);
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;

  h1 {
    color: #282828;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Poppins";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 157.143%;
    margin-bottom: 2.2rem;
  }

  .activity-tabs {
    margin-bottom: 2rem;

    ul {
      li {
        .nav-link {
          color: var(--text-dark);
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.4rem 0.8rem;
          background: transparent;
          border-radius: 0.6rem;
          -webkit-border-radius: 0.6rem;
          -moz-border-radius: 0.6rem;
          -ms-border-radius: 0.6rem;
          -o-border-radius: 0.6rem;
        }
      }
    }
  }
}

.task-activity .activity-tabs ul li .nav-link:active,
.task-activity .activity-tabs ul li .nav-link.active {
  background: rgba(111, 0, 255, 0.05);
  color: #6F00FF;
}

.mb-17 {
  margin-bottom: 1.7rem !important;
}

.message-board {
  .message {
    .user-label {
      min-width: 1.7rem;
      width: 1.7rem;
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Poppins";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: #6F00FF;
      color: var(--text-white);
      border-radius: 1.7rem;
      -webkit-border-radius: 1.7rem;
      -moz-border-radius: 1.7rem;
      -ms-border-radius: 1.7rem;
      -o-border-radius: 1.7rem;
    }

    .username {
      color: #343B4E;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Poppins";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0rem;

      span {
        color: #666D80;
        font-weight: 400;
      }
    }

    .time {
      color: #949CA9;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Poppins";
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .message-grp {
    gap: 1.4rem;
    padding: 0rem 2rem;
  }
}

.task-foot {
  padding-top: 2rem;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5rem;

    li {
      p {
        color: #666D80;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Poppins";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0rem;
      }
    }
  }
}

.alert {

  &.alert-purple {
    background: #6f00ff30;
    color: $primary-color;
    border-color: #6f00ffb0;
  }
}


// config modal for template title and description 

.config-add-modal {
  overflow: hidden !important;

  .modal-dialog {
    margin: 7.64rem 0rem 0rem auto;
    max-width: 57.3rem;
    height: 100%;

    .modal-content {
      border: 0rem;
      background: $text-white;
      box-shadow: $shadow-2;
      border-radius: 0rem;
      -webkit-border-radius: 0rem;
      -moz-border-radius: 0rem;
      -ms-border-radius: 0rem;
      -o-border-radius: 0rem;
      height: calc(100dvh - 7.64rem);

      .modal-header {
        border: 0.1rem solid #e0e0e0;
        background: rgba(52, 59, 78, 0.06);
        padding: 2.8rem 2.3rem 1.4rem 2.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .modal-title {
          color: $text-color;
          font-family: $font-family-base;
          font-size: #{$font-size-base + 0.2rem};
          font-style: $font-size-base;
          font-weight: $font-weight-700;
          line-height: $lh-normal;
          margin-bottom: 0rem;
        }
      }

      .modal-body {
        padding: 2.6rem 2.3rem;

        .form-group {

          .form-label {
            color: $text-dark-2;
            font-feature-settings: "liga"off, "clig"off;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-500;
            line-height: 157.143%;
            margin-bottom: 0.5rem;
          }

          .form-control {
            border: 0.1rem solid $bg-2;
            font-feature-settings: 'liga' off, 'clig' off;
            background: $text-white;
            color: $text-dark-2;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            padding: 1.5rem;
            border-radius: $radius-6;
            -webkit-border-radius: $radius-6;
            -moz-border-radius: $radius-6;
            -ms-border-radius: $radius-6;
            -o-border-radius: $radius-6;
            -webkit-font-feature-settings: 'liga' off, 'clig' off;
            -moz-font-feature-settings: 'liga' off, 'clig' off;

            &::placeholder {
              color: $text-light-grey;
            }

            &:focus,
            &:focus-visible,
            &:focus-within {
              border-color: $primary-color !important;
            }
          }
        }

        .mb-25 {
          margin-bottom: 2.5rem !important;
        }

        .modal-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          padding: 1rem;
        }

        &.webhook {

          .title {
            color: $text-dark-2;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-500;
            line-height: 157.143%;
          }

          .link {
            border: 0.1rem solid #E8E9EA;
            background: #FFF;
            border-radius: $radius-6;
            -webkit-border-radius: $radius-6;
            -moz-border-radius: $radius-6;
            -ms-border-radius: $radius-6;
            -o-border-radius: $radius-6;
            padding: 1.5rem;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: 157.143%;
          }

          .btn-copy {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-color;
            font-family: $font-family-base;
            font-size: $fs-10;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            line-height: $lh-normal;
            gap: 0.5rem;
            padding: 0.4rem 0.8rem;
            background: rgba(148, 156, 169, 0.09);
            border-radius: 0.6rem;
            -webkit-border-radius: 0.6rem;
            -moz-border-radius: 0.6rem;
            -ms-border-radius: 0.6rem;
            -o-border-radius: 0.6rem;
            border: 0rem;
          }
        }
      }
    }
  }
}


span {
  &.badge {
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: $lh-normal;
    @include flex-center;
    padding: 0.2rem 2.3rem;
    gap: #{$spacing-unit + 0.2rem};
    border-radius: 0.4rem;
    -webkit-border-radius: 0.4rem;
    -moz-border-radius: 0.4rem;
    -ms-border-radius: 0.4rem;
    -o-border-radius: 0.4rem;
    text-transform: capitalize;

    &.bg-gray-25 {
      color: $text-color;
      background: $bg-grey-25 !important;
    }

    &.bg-white-25 {
      border: 0.1rem solid $bg-2;
      background: $text-white;
      color: $text-color;
    }

    &.bg-orange-25 {
      background: $bg-orange-25;
      color: $text-orange-300;
    }

    &.bg-blue-25 {
      background: $bg-blue-25;
      color: $text-blue-300;
    }

    &.bg-red-25 {
      background: $bg-red-25;
      color: $text-red-300;
    }

    &.bg-yellow-25 {
      background: $bg-yellow-25;
      color: $text-yellow-300;
    }

    &.bg-purple-25 {
      background: $bg-purple-25;
      color: $primary-color;
    }

    &.bg-pink-25 {
      background: $bg-pink-25;
      color: $text-pink-300;
    }

    &.bg-green-25 {
      background: $bg-green-25;
      color: $text-green-300;
    }

  }
}

.form-group {

  .form-select {

    &.email-select {
      background: no-repeat;

      .task-config {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 0.5rem;

        .email-collab {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
          flex-wrap: wrap;

          .collab {
            color: $text-dark-2;
            font-feature-settings: "liga" off, "clig" off;
            font-family: $font-family-base;
            font-size: $fs-14;
            font-style: $font-style-normal;
            font-weight: $font-weight-500;
            line-height: 157.143%;
            display: flex;
            align-items: center;
            gap: 0.4rem;

            span {
              width: 2rem;
              height: 2rem;
              @include flex-center;
              line-height: 18%;
              font-size: $fs-12;
              text-transform: uppercase;
              border-radius: 5rem;
              -webkit-border-radius: 5rem;
              -moz-border-radius: 5rem;
              -ms-border-radius: 5rem;
              -o-border-radius: 5rem;
            }
          }

          &:nth-child(odd) {
            .collab {
              span {
                background-color: $primary-color;
                color: $text-white;
              }
            }
          }

          &:nth-child(even) {
            .collab {
              span {
                background-color: #61caff;
                color: $text-white;
              }
            }
          }
        }
      }
    }
  }
}