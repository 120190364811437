@import './variables';
@import './mixins';

.custom-form-styles {

  .form-group {
    margin-bottom: 2.5rem;
  }

  .form-control,
  .form-select {
    border: 0.1rem solid $bg-2;
    background-color: $text-white;
    padding: 1.5rem;
    border-radius: $radius-6;
    -webkit-border-radius: $radius-6;
    -moz-border-radius: $radius-6;
    -ms-border-radius: $radius-6;
    -o-border-radius: $radius-6;
    color: $text-dark;
    font-feature-settings: "liga"off, "clig"off;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    line-height: 157.143%;

    &::placeholder {
      color: $text-light-grey;
    }

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: $primary-color;
    }
  }

  .form-select {
    background-image: url("../icons/select-chevron.svg") !important;

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: $primary-color !important;
    }
  }

  .form-label {
    color: $text-dark-2;
    font-feature-settings: "liga"off, "clig"off;
    font-family: $font-family-base;
    font-size: $fs-14;
    font-style: $font-style-normal;
    font-weight: $font-weight-500;
    line-height: 157.143%;
    margin-bottom: 0.5rem;
  }

  .form-check {
    .form-check-input {
      width: 2rem;
      height: 2rem;
      margin: 0rem;
      background-color: #f3f3f4;
      border: 0.1rem solid #f3f3f4;

      &:focus {
        outline: 0;
        box-shadow: none;
        border: none;
      }

      &:checked[type="radio"] {
        background-image: url("../../assets/icons/checkbox-fill.svg");
        background-color: #6F00FF;
        border-color: #6F00FF;
      }

      &:checked[type="checkbox"] {
        background-image: url("../../assets/icons/check-right.svg");
        background-color: #6F00FF;
        border-color: #6F00FF;
      }
    }

    .form-check-label {
      color: $text-dark-2;
      font-feature-settings: 'liga'off, 'clig'off;
      font-family: $font-family-base;
      font-size: $fs-14;
      font-style: $font-style-normal;
      font-weight: $font-weight-500;
      line-height: 157.143%;
      margin-bottom: 0rem;
    }
  }

  .preview-assign {
    .card {
      &.assign-card {
        &.preview {
          .form-check {
            .form-check-input {
              &:checked[type="radio"] {
                background-image: url("../icons/checkbox-fill2.svg");
                background-position: left;
                background-size: contain;
              }
            }
          }
        }
      }
    }
  }

  .custom-form-styles-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1.9rem;
  }
}