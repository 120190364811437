@import './assets/scss/main.scss';
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/idea.css";

// @import '~@fortawesome/fontawesome-free/css/all.css';
// @import "src/assets/css/all.min.css";

/* Variables */
@import "@angular/cdk/overlay-prebuilt.css";

:root {
  --primary: #ddfc64;
  --text-white: #ffffff;
  --text-dark: #000000;
  --Neutral-Solid-400: #818898;
  --Neutral-Solid-500: #666D80;
}

.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// Overlay panel styling
.overlay-panel {
  display: flex;
  flex-direction: column;
  // background-color: #30343b;
  padding: 20px;
}

.modal-width-sm {
  max-width: 400px;
  width: 400px;
}

.modal-width-xl {
  max-width: 800px;
  width: 800px;
}

.modal-width-xxl {
  max-width: 1200px;
  width: 1200px;
}

.overlay-content {
  height: 500px;
  overflow-y: auto;
  margin-top: auto;
  margin-bottom: auto;
}

#jsonPreviewContentI {
  position: relative;
}

// Input Styling

.select2-container .select2-selection.select2-selection--multiple,
.select2-container .select2-selection.select2-selection--single {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #757575;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  height: 46px;
}

.select2-container .select2-selection.select2-selection--multiple .select2-selection__choice,
.select2-container .select2-selection.select2-selection--single .select2-selection__choice,
.select2-search__field {
  margin-top: 0 !important;
}

.select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  height: 46px;
}

.select2-search__field {
  font-family: titillium-web-thin !important;
  height: 100% !important;
}

ng-select2.textEdit.hasContent~.label,
ng-select2.textEdit.isOpen~.label,
ng-select2.textEdit:focus~.label {
  top: -20px;
  left: 0;
  font-size: 14px;
  color: var(--primary);
}

.ng-touched.ng-invalid .textEdit~.span,
.ng-touched.ng-invalid input~.span,
.ng-touched.ng-invalid textarea~.span,
.ng-touched.ng-invalid ng-select2~.span,
.ng-touched>input:focus~.span {
  opacity: 1;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #ff3333;
  font-weight: 700;
  line-height: 13px;
  font-family: Lato, sans-serif;
}

@keyframes inputHighlighter {
  0% {
    background: #c1ff11;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.center-panel .cdk-drop-list-dragging {
  border: 1px dashed white;
}

.hBEuxg .blockbuilder-branding {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  background: rgb(238, 238, 238);
  border-top: 1px solid rgb(214, 217, 220);
  z-index: 100;
  display: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
}

.text-danger {
  color: #c83232;
}

.scrollbar-custom,
.select2-container--default .select2-results>.select2-results__options {
  scrollbar-width: thin;
  scrollbar-color: hsla(211, 13%, 63%, 0.4) #bbbbbb00;
}

.scrollbar-custom::-webkit-scrollbar,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar-custom::-webkit-scrollbar-track,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track {
  background-color: #bbbbbb00;
}

.scrollbar-custom::-webkit-scrollbar-thumb,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
  background-color: hsla(211, 13%, 63%, 0.4);
}

.scrollbar-custom::-webkit-scrollbar-track,
.scrollbar-custom::-webkit-scrollbar-thumb,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

// / Example CSS customization /

$color_1: #000000;
$color_2: #1ea97c;
$color_3: #cc8925;
$color_4: #ff5757;

.simple-notification-wrapper {
  .simple-notification {
    width: 100%;
    padding: 1rem 1rem 1rem 4.5rem;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-bottom: 1rem;
    color: $color_1;
    cursor: pointer;
    transition: all .5s;
    min-height: 6.9rem;
    margin: 0 0 1rem 0;
    box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
    backdrop-filter: blur(1rem);

    .icon {
      width: 3rem;
      height: 3rem;
      top: 14%;
      left: 2%;
      padding: 0rem;

      svg {
        color: $color_1;
      }
    }

    .sn-title {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 140% !important;
      text-transform: capitalize;
      padding: 0rem;
    }

    .sn-content {
      font-size: 1.2rem !important;
      line-height: 140% !important;
      text-transform: capitalize;
      font-style: normal !important;
      font-weight: 500 !important;
    }

    .sn-progress-loader {
      height: 0rem;
    }
  }

  .simple-notification.success {
    background: rgba(228, 248, 240, 0.7);
    border: solid $color_2;
    border-width: 0 0 0 0.6rem;
    color: $color_2;

    .icon {
      svg {
        fill: $color_2 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_2 !important;
    }

    .sn-content {
      color: $color_2 !important;
    }
  }

  .simple-notification.warn {
    background: rgba(255, 242, 226, 0.7);
    border: solid $color_3;
    border-width: 0 0 0 0.6rem;
    color: $color_3;

    .icon {
      svg {
        fill: $color_3 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_3 !important;
    }

    .sn-content {
      color: $color_3 !important;
    }
  }

  .simple-notification.error {
    background: rgba(255, 231, 230, 0.7) !important;
    border: solid $color_4;
    border-width: 0 0 0 0.6rem;
    color: $color_4 !important;

    .icon {
      svg {
        fill: $color_4 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_4 !important;
    }

    .sn-content {
      color: $color_4 !important;
    }
  }
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}