// colors
$primary-color: #6F00FF;
$primary-color-2: #8247ff;
$primary-color-3: #6118fc;
$primary-2: #ddfc64;
$primary-3: #c1ff11;
$secondary-color: #E6E7EA;
$secondary-color-2: #666d80;
$text-light-grey: #949ca9;
$text-light-grey-2: #717070;
$text-grey: #627065;
$text-grey-2: #a9a5a5;
$text-grey-3: #4c4a4a;
$text-light: #7a828a;
$text-light-2: #a9a9aa;
$text-dark: #000000;
$text-white: #ffffff;
$text-dark-2: #282828;
$text-red: #ff0505;
$text-red-2: #ff4b4b;
$text-red-3: #ff0000;
$text-color: #343B4E;
$text-color-2: #0b2110;
$text-purple: #3754db;
$Neutral-Solid-400: #818889;
$Neutral-Solid-500: #828a97;
$Neutral-Solid-600: #8f8a8a;
$text-dark-grey: #474747;
$border-1: #e0e0e0;
$border-2: #e1e1e1;
$border-grey: rgba(148, 156, 169, 0.44);
$border-light-grey-2: #d0d1d3;
$border-light-grey: #d7d6d8;
$border-light: #eceff3;
$bg-1: #f4f4f4;
$bg-2: #e8e9ea;
$bg-3: rgba(148, 156, 169, 0.09);
$bg-4: rgba(148, 156, 169, 0.33);
$bg-5: rgba(111, 0, 255, 0.02);
$bg-6: rgba(130, 71, 255, 0.05);
$bg-7: rgba(151, 71, 255, 0.09);
$bg-8: rgba(148, 156, 169, 0.27);
$bg-9: rgba(52, 59, 78, 0.06);
$bg-10: rgba(97, 24, 252, 0.12);
$bg-11: rgba(148, 156, 169, 0.22);
$bg-12: #F3F3F4;
$bg-13: #F5F6F7;
$bg-14: rgba(52, 59, 78, 0.12);
$bg-15: rgba(52, 59, 78, 0.07);
$bg-16: rgba(148, 156, 169, 0.26);
$gradient-background-1: linear-gradient(180deg, #e9f7ff 0%, #fff 100%);
$gradient-background-2: linear-gradient(180deg, #8247ff 0%, #2b7f99 100%);
$gradient-background-3: linear-gradient(157deg, #248EC3 14.71%, #9E00FF 147.5%);
$gradient-background-4: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%),
linear-gradient(90deg, #C1FF11 0%, #EFFBCC 100%);
$gradient-background-5: linear-gradient(90deg,
  rgba(193, 255, 17, 0.2) 42.67%,
  rgba(163, 255, 71, 0.2) 58.67%);
$gradient-background-6: linear-gradient(180deg,
  rgba(116, 9, 255, 0.13) 0%,
  rgba(156, 206, 15, 0.13) 100%);

$bg-grey-25: #e9ebee;
$bg-orange-25: #fde4cc;
$text-orange-300: #ff7c03;
$bg-blue-25: #cae7f6;
$text-blue-300: #0098e5;
$bg-red-25: #ffcecb;
$text-red-300: #fc0c0c;
$bg-yellow-25: #fbffcb;
$text-yellow-300: #c6b307;
$bg-purple-25: #6f00ff1c;
$bg-pink-25: #fc188540;
$text-pink-300: #fc1885;
$bg-green-25: #c1ff116e;
$text-green-300: #79a205;

//  fonts
$font-family-base: "Poppins",
sans-serif;
$font-family-base-2: "Libre Franklin";
$font-family-base-3: "Inter";
$fs-10: 1rem;
$fs-11: 1.1rem;
$fs-12: 1.2rem;
$fs-14: 1.4rem;
$font-size-base: 1.6rem;
$fs-28: 2.8rem;
$font-weight-normal: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-style-normal: normal;
$lh-normal: normal;


//Shadows
$shadow-1: 0rem 0.8rem 1.6rem 0rem rgba(148, 156, 169, 0.15);
$shadow-2: 0rem 0.9rem 2.5rem 0rem rgba(0, 0, 0, 0.1);
$shadow-3: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.06);
$shadow-4: 0rem 1rem 1.5rem 0rem rgba(24, 59, 86, 0.2);
$shadow-5: 0rem 0rem 0.4rem 0rem rgba(24, 59, 86, 0.05);
$shadow-6: 0rem 0.2rem 0.5rem 0rem rgba(24, 59, 86, 0.25);
$shadow-7: 0rem 0.4rem 0.4rem 0rem rgba(24, 59, 86, 0.1);
$shadow-8: 0rem 0.3rem 0.9rem 0rem rgba(0, 0, 0, 0.07);


// Spacing
$spacing-unit: 0.8rem;
$padding-base: $spacing-unit * 2;
$margin-base: $spacing-unit * 3;


// Radius
$radius-1: 1rem;
$radius-6: 0.6rem;
$radius-8: 0.8rem;
$radius-16: 1.6rem;
$radius-18: 1.8rem;

// BreakPoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;