@import './variables';
@import './mixins';

.dropdown {

  .btn.dropdown-toggle {

    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    background: $text-white;
    box-shadow: $shadow-2;
    border: 0rem;
    padding: 1.2rem 1.1rem;
    min-width: 17.2rem;

    .drop-head {
      h5 {
        color: $Neutral-Solid-600;
        font-family: $font-family-base;
        font-size: $fs-12;
        font-style: $font-style-normal;
        font-weight: $font-weight-normal;
        line-height: $lh-normal;
      }
    }

    .drop-footer {
      border-top: 0.1rem solid $border-2;
      margin-top: 0.5rem;
      padding-top: 1.1rem;

      .btn {
        font-size: 1.2rem;
      }
    }

    .dropdown-item {
      color: $text-color;
      font-family: $font-family-base;
      font-size: $fs-12;
      font-style: $font-style-normal;
      font-weight: $font-weight-normal;
      line-height: $lh-normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.4rem;
      padding: 0.2rem 0.8rem;

      &:hover,
      &:active {
        background: transparent;
      }
    }

    .Collaborators {
      li {
        .dropdown-item {
          color: $text-color;
          font-family: $font-family-base;
          font-size: $fs-14;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          line-height: $lh-normal;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.4rem;
          padding: 0.2rem 0.8rem;

          span {
            width: 2rem;
            height: 2rem;
            @include flex-center;
            line-height: 18%;
            font-size: $fs-12;
            text-transform: uppercase;
            border-radius: 5rem;
            -webkit-border-radius: 5rem;
            -moz-border-radius: 5rem;
            -ms-border-radius: 5rem;
            -o-border-radius: 5rem;
          }

          &:hover,
          &:active {
            background: transparent;
          }
        }

        &:nth-child(odd) {
          .dropdown-item {
            span {
              background-color: $primary-color;
              color: $text-white;
            }
          }
        }

        &:nth-child(even) {
          .dropdown-item {
            span {
              background-color: #61caff;
              color: $text-white;
            }
          }
        }
      }
    }
  }
}